import { API_URL } from "src/constants";
const baseurl = API_URL;
let user = `${baseurl}/api/v1/user`;
let newuser = `${baseurl}/api/v1`;
let nft = `${baseurl}/nft`;
let collection = `${baseurl}/collection`;
let network = ''
let order = `${baseurl}/order`;
let activity = `${baseurl}/activity`;
let bid = `${baseurl}/order`;
let notification = `${baseurl}/api/v1/notification`;
export const websiteName =
  window.location.protocol + '//' + window.location.host;

export const socketURL = "wss://marketplaceapi.rareoasis.io/";

const apiConfig = {
  //USER
  connectWallet: `${baseurl}/login`,
  profile: `${baseurl}/user/profile`,
  updateProfile: `${baseurl}/user/profile`,
  userSubscribe: `${user}/userSubscribe`,
  recentCollectionList: `${collection}`,

  userOwendCount: `${order}/owend/`,
  userCreatedCount: `${order}/created/`,
  createReports: `${user}/createReports`,
  getUserDetails: `${baseurl}/user/users/`,
  userFollowingCount: `${baseurl}/user/follow/`,
  userFollowerCount: `${baseurl}/user/follow/`,
  userLikesCount: `${order}/like-list/`,
  nftSoldCount: `${order}/soled/`,
  followUnfollow: `${baseurl}/user/followUnfollow/`,

  //nft
  ipfsUpload: `${baseurl}/upload`,
  jsonIpfsUpload: `${baseurl}/upload`,
  createNFT: `${baseurl}/nft`,
  uploadNFT: `${baseurl}/file`,
  likeDislikeNft: `${nft}/likeDislikeNft/`,
  sendNFT: `${nft}/sendNFT`,
  showNftHistory: `${activity}`,
  getSign: `${nft}/sign/`,
  //order
  createOrder: `${order}`,
  allListOrder: `${order}/all`,
  viewOrder: `${order}/`,
  editOrder: `${order}`,
  buyOrder: `${order}/buy`,
  likeDislikeOrder: `${order}/like/`,
  sendOrderToUser: `${order}/accept`,
  cancelOrder: `${order}/cancel`,
  cancelBid:`${baseurl}/order/bid`,

  //collection
  createCollection: `${collection}`,
  myCollectionList: `${collection}/my`,
  viewCollection: `${collection}/`,
  Summary: `${order}/summary`,
  Detail: `${collection}/search/`,
  collectionList: `${collection}`,

  //nfttopseller
  topSeller: `${baseurl}/user/topSalers`,

  userList: `${baseurl}/user`,

  //collectionOrderList
  collectionOrderList: `${order}/same`,

  //activity
  allListActivity: `${activity}/allListActivity`,
  listActivity: `${activity}/listActivity`,

  //activity
  createBid: `${order}/bid`,
  hotBid: `${bid}/hotbid`,
  showActivity: `${activity}`,

  //notification
  readNotification: `${notification}/readNotification`,
  
  serviceFee: 5
  // amount-amount*serviceFee/100
};
export default apiConfig;
