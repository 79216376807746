import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  Avatar,
  MenuItem,
  Box,
  Container,
  Menu,
  Grow,
  Paper,
  Popper,
  MenuList,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent
} from "@material-ui/core";
// import InputLabel from '@mui/material/InputLabel';
// import  { SelectChangeEvent } from '@mui/material/Select';
import MenuIcon from "@material-ui/icons/Menu";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  Fragment,
} from "react";
import { Link, useHistory } from "react-router-dom";
import Logo from "./../../component/Logo";
import { IoIosNotifications } from "react-icons/io";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { useWeb3React } from "@web3-react/core";
import { ACTIVE_NETWORK, NetworkContextName } from "src/constants";
import { UserContext } from "src/context/User";
import { copyTextById, sortAddress } from "src/utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchBar from "./SearchBar";
import "../../scss/layout/_topbar.scss";
// import { useTranslation, withTranslation, Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import cookies from 'js-cookie'
import classNames from 'classnames'

const languages = [
  {
    dir:"ja",
    code: 'ja',
    name: 'Japan',
  },
  {
    dir:"en",
    code: 'en',
    name: 'English',
  }
]


const Themes = ["Dark Theme", "White Theme"];

const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: "15px",
    lineHeight: "24px",
    fontWeight: "500",
    borderRadius: 0,
    minWidth: "auto",
    color: theme.secondColor,
    height: "31px",
    padding: "0px 7px",
    letterSpacing: "1px",
    margin: "0 7px",
    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: theme.mainColor,
      padding: "15px !important",
      height: "51px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    "&.active": {
      color: theme.colorfulMainColor,
    },
    "&:hover": {
      color: theme.colorfulMainColor,
    },
    "&:last-child": {
      backgroundColor: "#1ed760",
    },
  },
  menuButton1: {
    width: "100%",
  },
  login: {
    backgroundColor: "#583DFF",
    marginLeft: "10px",
  },
  loginButton: {
    height: "28px",
    width: "28px",
  },
  toolbar: {
    display: "flex",
    padding: "10px 0",
    justifyContent: "space-between",
    height: "100%",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
      height: "100%",
    },
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    marginLeft:'10px',
    // width: "140px",
    marginBottom: "10px",
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    background: "#000",
    width: "260px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  drawericon: {
    color: "#000",
    position: "absolute",
    top: "0px",
    right: "-10px",
    fontSize: "25px",
  },
  logoImg: {
    width: "75px",
    // height: '44.5px',
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
    },
  },
  menuMobile: {
    fontSize: "16px",
    fontWeight: "400",
    fontStyle: "normal",
    letterSpacing: "-0.6px",
    lineHeight: "1.75",
    color: "#fff",
    borderBottom: "1px solid #3e3e3e",
    padding: "16px",
    "@media (max-width: 500px)": {
      padding: "7px 0",
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
  },
  paper1: {
    background: "black",
    color: "white",
  },
  containerHeight: {
    height: "100%",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
  },
  search: {
    height: "31px",
    position: "relative",
    color: "#ABABAB",
    borderRadius: "100px",
    backgroundColor: "#E6E6E6",
    border: "1px solid #fff",
    "&:hover": {
      backgroundColor: "#ececec",
      border: "1px solid #91b44e",
    },
    marginLeft: 20,
    width: "140px",
    maxWidth: "257px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "180px",
    },
  },
  searchIcon: {
    fontSize: "16px",
    padding: theme.spacing(0, 2),
    color: "#000000",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    fontSize: "16px",
  },
  wallet: {
    fontSize: "14px",
    fontWeight: "400",
    fontStyle: "normal",
    lineHeight: "21px",
    color: "#ffffff",
    padding: "0 15px",
    background: "#ff343f",
    borderRadius: "50px",
    height: "31px",
    margin: "1px 8px",
    // "&:hover": {
    //   background: "transparent",
    //   color: theme.mainColor,
    //   border: "solid 1px #E25AF8",
    //   margin: "0 7px",
    // },
    "@media (max-width: 900px)": {
      marginLeft: "12px",
      marginTop: "12px",
    },
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: "13px",
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100px",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
  submenu: {
    borderTop: "3px solid #91b44e",
    top: "25px !important",
  },
  cThemeSelector: {
    "& span, & svg": {
      fontSize: "20px",
      color: theme.mainColor,
    },
  },
  cHeaderOfSelector: {
    color: theme.secondColor,
  },
  language: {
    background: 'none',
    border: 'none',
    width: '25px',
    margin: '0 20px',
  }
}));

function scrollToTop(event) {
  document.getElementById("myHeader").scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})
}

export default function Header() {
  const currentLanguageCode = localStorage.getItem("i18nextLng")  || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()
  const [language, setLanguage] = React.useState(currentLanguageCode);
  // const releaseDate = new Date('2021-03-07')
  // const timeDifference = new Date() - releaseDate
  // const number_of_days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
  // const [age, setAge] = React.useState<string | number>('');
  const [open, setOpen] = React.useState(false);
  const headersData = [
    {
      uuid: 1,
      label: t('TOPBAR.MARKET_PLACE'),
      href: "/marketplace",
    },
    {
      uuid: 2,
      label: t('TOPBAR.MY_ACTIVITY'),
      href: "/activity",
    },
    // {
    //   label: t('TOPBAR.CREATE'),
    //   href: "/create",
    // },
    // {
    //   label: "How it works",
    //   href: "/faq",
    // },
  ];
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const { chainId, account } = useWeb3React();
  const [openThemeSelection, setOpenThemeSelection] = useState(false);
  const user = useContext(UserContext);

  const handleClose1 = () => {
    setAnchorEl(null);
  };

  const {
    loginButton,
    menuMobile,
    menuButton,
    menuButton1,
    toolbar,
    drawerContainer,
    drawericon,
    login,
    logoDrawer,
    containerHeight,
    mainHeader,
    wallet,
    submenu,
    cThemeSelector,
    cHeaderOfSelector,
  } = useStyles();
  const history = useHistory();

  const [headerPosition, setHeaderPosition] = useState(false);
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    document.body.dir ="en"
    
    const setResponsiveness = () => {
      window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    const scroll = () => {
      const number = document.scrollingElement?.scrollTop || 0;
      if (number >= 10) {
        setHeaderPosition(true);
      } else if (number < 10) {
        setHeaderPosition(false);
      }
    }

    scroll();
    window.addEventListener("scroll", () => scroll());
    return () => {
      window.removeEventListener("scroll", () => scroll());
      window.removeEventListener("resize", () => setResponsiveness());
    }
   
  }, [currentLanguage, t]);
  const [open1, setOpen1] = useState({ community: false, user: false });
  const anchorRef = { community: useRef(null), user: useRef(null) };

  const handleThemeSelection = (showed) => {
    setOpenThemeSelection(showed);
  };

  const handleThemeItemClick = (theme) => {
    if (theme === "Dark Theme") {
      user.setCurrentTheme(1);
    } else if (theme === "White Theme") {
      user.setCurrentTheme(2);
    }
    setOpenThemeSelection(false);
  };

  const handleToggle = (name) => {
    setOpen1({ ...open1, [name]: !open1[name] });
  };

  const handleClose2 = (event, name) => {
    if (
      anchorRef[name].current &&
      anchorRef[name].current.contains(event.target)
    ) {
      return;
    }

    setOpen1({ ...open1, [name]: false });
  };

  function handleListKeyDown(event, name) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen1({ ...open1, [name]: false });
    }
  }

  function alertConnectWallet() {
    toast.error(t('TOPBAR.ERROR_WALLET'));
  }

  // return focus to the button when we transitioned from !open -> open
  // const prevOpen = React.useRef(open1);
  // React.useEffect(() => {
  //   if (prevOpen.current === true && open1 === false) {
  //     anchorRef.current.focus();
  //   }

  //   prevOpen.current = open1;
  // }, [open1]);

  const displayDesktop = () => {
    return (
      <>
        <div className={`navbar white undefined ${headerPosition ? 'sticky' : ''}`} id="myHeader">
          <div className="container">
            <div className="row w-100-nav">
              <div className="logo px-0">
                <div className="navbar-title navbar-item">
                  <Link to="/" className="non-active">
                    <Logo className="img-fluid d-4" />
                      <h2 className="logo_text" style={{color:"#fff", fontSize:"14px"}}>{t('TOPBAR.NFT_TEXT_LOGO')}</h2> 
                  </Link>
                </div>
              </div>
              <div className="search">
                <SearchBar />
              </div>
             
              <div className="breakpoint__xl-only">
                <div className="menu">
                  {getMenuButtons()}
                  {user.isLogin && (account || user.tronWalletAddress) ? (
                    <div className="navbar-item">
                      <div>
                        <Button
                          {...{
                            color: "inherit",
                            to: "/create",
                            component: Link,
                            className: "dropdown-custom dropdown-toggle btn",
                          }}
                        >
                          {t('TOPBAR.CREATE')}
                          <span className="lines"></span>
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="navbar-item">
                      <div>
                        <Button
                          {...{
                            color: "inherit",
                            className: "dropdown-custom dropdown-toggle btn",
                          }}
                          onClick={alertConnectWallet}
                        >
                          {t('TOPBAR.CREATE')}
                          <span className="lines"></span>
                        </Button>
                      </div>
                    </div>
                  )}
                 
                  {/* Notification */}
                  {/* {user.isLogin && account && (
                    <IconButton
                      aria-label="delete"
                      size="small"
                      component={Link}
                      to="/notification"
                      className="notification"
                    >
                      <IoIosNotifications
                        fontSize="30"
                        style={{ color: "#a9acaf" }}
                      />
                      {user.unreadCount && user.unreadCount > 0 ? (
                        <span className="dots"></span>
                      ) : null}
                    </IconButton>
                  )} */}
                  <Popper
                    open={open1.community}
                    anchorEl={anchorRef.community.current}
                    role={undefined}
                    transition
                    disablePortal
                    className={submenu}
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom" ? "center top" : "center bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener
                            onClickAway={(e) => handleClose2(e, "community")}
                          >
                            <MenuList
                              autoFocusItem={open1}
                              id="menu-list-grow"
                              onKeyDown={(e) => handleListKeyDown(e, "community")}
                            >
                              <MenuItem component={Link} to="/Metaarts-token">
                                Metaarts Token
                              </MenuItem>
                              <MenuItem component={Link} to="/faq">
                                Discussion
                              </MenuItem>
                              <MenuItem>Voting</MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                  
                </div>
                
              </div>
              {/* <button
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                className="language"
                style = {{width: '20px', margin: '0 20px', background: 'none', border: 'none'}}
              >
                  <GlobeIcon />
                  </button> */}
                  {/* <ul style={{width: '50px', position: 'relative', top: '44px', left: '-54px'}} className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <span className="dropdown-item-text">{t('language')}</span>
              </li>
              {languages.map(({ code, name, country_code }) => (
                <li key={country_code} style={{listStyle: 'none'}}>
                  <a
                    href="#"
                    className={classNames('dropdown-item', {
                      disabled: currentLanguageCode === code,
                    })}
                    onClick={() => {
                      i18next.changeLanguage(code)
                    }}
                  >
                    <span
                      className={`flag-icon flag-icon-${country_code} mx-2`}
                      style={{
                        opacity: currentLanguageCode === code ? 0.5 : 1,
                      }}
                    ></span>
                    {name}
                  </a>
                </li>
              ))}
            </ul> */}
           
              <input
                type="text"
                id="headerWalletAddress"
                value={user.userData?.walletAddress || ''}
                style={{ display: "none" }}
                readOnly="readOnly"
              />
              <div className="mainside">
                {user.isLogin && (account || user.tronWalletAddress) ? (
                  <Button
                    className={wallet}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={() => copyTextById("headerWalletAddress")}
                  >
                    {account
                      ? sortAddress(account)
                      : sortAddress(user.tronWalletAddress)}{" "}
                  </Button>
                ) : (
                  <Button
                    className={wallet}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    to="/wallet"
                    component={Link}
                  >
                    {t('TOPBAR.CONNECT_WALLET')}
                  </Button>
                )}
              </div>

              <div className="logged">
                {user.isLogin && (account || user.tronWalletAddress) && (
                  <IconButton
                    aria-label="delete"
                    size="small"
                    className={login}
                    ref={anchorRef.user}
                    aria-controls={open1.user ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={() => handleToggle("user")}
                  >
                    {" "}
                    <Avatar
                      className={loginButton}
                      src={
                        user?.userData?.profilePic
                          ? user?.userData?.profilePic
                          : "/images/default-avatar-v2.jpg"
                      }
                    />
                  </IconButton>
                )}
                <Popper
                  open={open1.user}
                  anchorEl={anchorRef.user.current}
                  role={undefined}
                  transition
                  disablePortal
                  className={submenu}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom" ? "center top" : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener
                          onClickAway={(e) => handleClose2(e, "user")}
                        >
                          <MenuList
                            autoFocusItem={open1}
                            id="menu-list-grow"
                            onKeyDown={(e) => handleListKeyDown(e, "user")}
                          >
                            <MenuItem
                              component={Link}
                              to={{
                                pathname: "/user",
                                search: user.userData._id,
                              }}
                            >
                              {t('TOPBAR.MY_PROFILE')}
                            </MenuItem>
                            <MenuItem component={Link} to="/edit-profile">
                              {t('TOPBAR.EDIT_PROFILE')}
                            </MenuItem>
                            <MenuItem
                              component={Link}
                              to="/wallet"
                              onClick={() => {
                                user.logoutHanlder();
                              }}
                            >
                             {t('TOPBAR.LOGOUT')}
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
              <Button style={{width: '30px'}} sx={{ display: 'block', mt: 2 }} onClick={handleOpen}>
          </Button>
          <FormControl sx={{ m: 1, maxWidth: 120 }} style={{width: '120px', marginTop: '-18px'}}>
            {/* <InputLabel id="demo-controlled-open-select-label" style={{color: '#fff'}}>{t('TOPBAR.LANGUAGE')}</InputLabel> */}
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              // open={open}
              // onClose={handleClose}
              // onOpen={handleOpen}
              defaultValue={language}
              // value={languages.name}
              label="Language"
              onClick={(e) => {
                i18next.changeLanguage(e.target.value)
              }}
            >
               {/* {languages.map(({ code, name, country_code }) => ( 
               
                <MenuItem key={country_code} value={code}>{{name}}</MenuItem>
               ))} */}
              <MenuItem value={'ja'} style={{color:'#fff'}}>日本語</MenuItem>
              <MenuItem value={'en'} style={{color:'#fff'}}>English</MenuItem>
            </Select>
          </FormControl>
            </div>
          </div>
        </div>
      </>
      // <Container maxWidth="lg" className="p-0">
      //   <Toolbar className={toolbar}>
      //     {productLogo}
      //     <SearchBar />
      //     <Grid
      //       container
      //       direction="row"
      //       justifyContent="flex-end"
      //       alignItems="center"
      //       style={{ paddingLeft: "0px" }}
      //     >
      //       {/* <div className={search}>
      //         <div className={searchIcon}>
      //           <SearchIcon />
      //         </div>
      //         <InputBase
      //           placeholder="Search items, collections, creators"
      //           classes={{
      //             root: inputRoot,
      //             input: inputInput,
      //           }}
      //           inputProps={{ "aria-label": "search" }}
      //         />
      //       </div> */}
      //       {getMenuButtons()}

      //       {user.isLogin && (account || user.tronWalletAddress) ? (
      //         <Button
      //           {...{
      //             color: "inherit",
      //             to: "/create",
      //             component: Link,
      //             className: menuButton,
      //           }}
      //         >
      //           Create
      //         </Button>
      //       ) : (
      //         <Button
      //           {...{
      //             color: "inherit",
      //             className: menuButton,
      //           }}
      //           onClick={alertConnectWallet}
      //         >
      //           Create
      //         </Button>
      //       )}

      //       {/* <Button
      //         ref={anchorRef.community}
      //         aria-controls={open1.community ? "menu-list-grow" : undefined}
      //         aria-haspopup="true"
      //         onClick={() => handleToggle("community")}
      //         endIcon={<ExpandMoreIcon />}
      //         className={menuButton}
      //       >
      //         Community
      //       </Button> */}
      //       {user.isLogin && account && (
      //         <IconButton
      //           aria-label="delete"
      //           size="small"
      //           component={Link}
      //           to="/notification"
      //         >
      //           <IoIosNotifications
      //             fontSize="30"
      //             style={{ color: "#a9acaf" }}
      //           />
      //           {user.unreadCount && user.unreadCount > 0 ? (
      //             <span className="dots"></span>
      //           ) : null}
      //         </IconButton>
      //       )}

      //       {/* <IconButton
      //         aria-label="delete"
      //         size="small"
      //         style={{
      //           background: "#fff3",
      //           boxShadow: "#8888 0px 0px 1px 2px",
      //         }}
      //         onClick={() => {
      //           setOpenThemeSelection(true);
      //         }}
      //       >
      //         <img
      //           src="images/home-icon/theme.png"
      //           style={{ width: "20px", height: "20px", margin: "3px" }}
      //         />
      //       </IconButton> */}
      //       {/* <Dialog
      //         onClose={() => handleThemeSelection(false)}
      //         aria-labelledby="simple-dialog-title"
      //         open={openThemeSelection}
      //       >
      //         <DialogTitle
      //           id="simple-dialog-title"
      //           className={cHeaderOfSelector}
      //         >
      //           Select Theme
      //         </DialogTitle>
      //         <List>
      //           {Themes.map((theme) => (
      //             <ListItem
      //               button
      //               onClick={() => handleThemeItemClick(theme)}
      //               key={theme}
      //             >
      //               <ListItemText className={cThemeSelector} primary={theme} />
      //             </ListItem>
      //           ))}
      //         </List>
      //       </Dialog> */}
      //       <Popper
      //         open={open1.community}
      //         anchorEl={anchorRef.community.current}
      //         role={undefined}
      //         transition
      //         disablePortal
      //         className={submenu}
      //       >
      //         {({ TransitionProps, placement }) => (
      //           <Grow
      //             {...TransitionProps}
      //             style={{
      //               transformOrigin:
      //                 placement === "bottom" ? "center top" : "center bottom",
      //             }}
      //           >
      //             <Paper>
      //               <ClickAwayListener
      //                 onClickAway={(e) => handleClose2(e, "community")}
      //               >
      //                 <MenuList
      //                   autoFocusItem={open1}
      //                   id="menu-list-grow"
      //                   onKeyDown={(e) => handleListKeyDown(e, "community")}
      //                 >
      //                   <MenuItem component={Link} to="/Metaarts-token">
      //                     Metaarts Token
      //                   </MenuItem>
      //                   <MenuItem component={Link} to="/faq">
      //                     Discussion
      //                   </MenuItem>
      //                   <MenuItem>Voting</MenuItem>
      //                 </MenuList>
      //               </ClickAwayListener>
      //             </Paper>
      //           </Grow>
      //         )}
      //       </Popper>
      //       {/* <Button
      //         className={menuButton}
      //         aria-controls="simple-menu"
      //         aria-haspopup="true"
      //         onClick={handleClick}
      //         endIcon={<ExpandMoreIcon />}
      //       >
      //         Community
      //       </Button> */}
      //       <input
      //         type="text"
      //         id="headerWalletAddress"
      //         value={user.userData?.walletAddress}
      //         style={{ display: "none" }}
      //       />
      //       <div style={{ width: "135px" }}>
      //         {user.isLogin && (account || user.tronWalletAddress) ? (
      //           <Button
      //             className={wallet}
      //             aria-controls="simple-menu"
      //             aria-haspopup="true"
      //             onClick={() => copyTextById("headerWalletAddress")}
      //           >
      //             {account
      //               ? sortAddress(account)
      //               : sortAddress(user.tronWalletAddress)}{" "}
      //           </Button>
      //         ) : (
      //           <Button
      //             className={wallet}
      //             aria-controls="simple-menu"
      //             aria-haspopup="true"
      //             to="/wallet"
      //             component={Link}
      //           >
      //             Connect wallet
      //           </Button>
      //         )}
      //       </div>
      //       {user.isLogin && (account || user.tronWalletAddress) && (
      //         <IconButton
      //           aria-label="delete"
      //           size="small"
      //           className={login}
      //           ref={anchorRef.user}
      //           aria-controls={open1.user ? "menu-list-grow" : undefined}
      //           aria-haspopup="true"
      //           onClick={() => handleToggle("user")}
      //         >
      //           {" "}
      //           <Avatar
      //             className={loginButton}
      //             src={
      //               user?.userData?.profilePic
      //                 ? user?.userData?.profilePic
      //                 : "images/user1.png"
      //             }
      //           />
      //         </IconButton>
      //       )}
      //       <Popper
      //         open={open1.user}
      //         anchorEl={anchorRef.user.current}
      //         role={undefined}
      //         transition
      //         disablePortal
      //         className={submenu}
      //       >
      //         {({ TransitionProps, placement }) => (
      //           <Grow
      //             {...TransitionProps}
      //             style={{
      //               transformOrigin:
      //                 placement === "bottom" ? "center top" : "center bottom",
      //             }}
      //           >
      //             <Paper>
      //               <ClickAwayListener
      //                 onClickAway={(e) => handleClose2(e, "user")}
      //               >
      //                 <MenuList
      //                   autoFocusItem={open1}
      //                   id="menu-list-grow"
      //                   onKeyDown={(e) => handleListKeyDown(e, "user")}
      //                 >
      //                   <MenuItem
      //                     component={Link}
      //                     to={{
      //                       pathname: "/user",
      //                       search: user.userData._id,
      //                     }}
      //                   >
      //                     My Profile
      //                   </MenuItem>
      //                   <MenuItem component={Link} to="/edit-profile">
      //                     Edit Profile
      //                   </MenuItem>
      //                   <MenuItem
      //                     component={Link}
      //                     to="/wallet"
      //                     onClick={() => {
      //                       user.logoutHanlder();
      //                     }}
      //                   >
      //                     Logout
      //                   </MenuItem>
      //                 </MenuList>
      //               </ClickAwayListener>
      //             </Paper>
      //           </Grow>
      //         )}
      //       </Popper>
      //       {/* <Button variant="contained" size="small" color="secondary" onClick={handleClickOpen}>
      //   Connect
      // </Button> */}
      //     </Grid>
      //   </Toolbar>
      // </Container>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Container
        maxWidth={history.location.pathname !== "/" ? "lg" : false}
        className={containerHeight}
      >
        <Toolbar className={mainHeader}>
          <Drawer
            {...{
              anchor: "right",
              open: drawerOpen,
              onClose: handleDrawerClose,
            }}
          >
            <div className={drawerContainer}>
              <img style={{width: '153px', height: 'auto'}} className={logoDrawer} src="images/logo/logo-v4.png" alt="" />
              <h2 className="logo_text" style={{color:"#fff", fontSize:"14px", paddingLeft: "10px", paddingBottom: "15px"}}>{t('TOPBAR.NFT_TEXT_LOGO')}</h2> 
              <IconButton aria-label="delete" size="small" className={login}>
                {" "}
                {
                  user.isLogin && (account || user.tronWalletAddress) ? (
                    <Link to={`/user?${user?.userData?._id}`}>
                      <Avatar className={loginButton} src={user?.userData?.profilePic
                          ? user?.userData?.profilePic
                          : "images/user1.png"} />
                    </Link>
                  ) : (
                    <Avatar className={loginButton} src={user?.userData?.profilePic
                      ? user?.userData?.profilePic
                      : "images/user1.png"} />
                  )
                }

              </IconButton>
              {getDrawerChoices()}
              {/* <Button
              // className={menuButton}
              className={menuButton}
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
              endIcon={<ExpandMoreIcon />}
            >
              Community
            </Button> */}
              {/* <Button
                className={wallet}
                aria-controls="simple-menu"
                aria-haspopup="true"
                to="/wallet"
                component={Link}
              >
                Connect wallet
              </Button> */}
              <Button
                {...{
                  color: "inherit",
                  to: "/create",
                  component: Link,
                  className: menuButton1,
                }}
              >
                <MenuItem className={menuMobile}>{t('TOPBAR.CREATE')}</MenuItem>
                {/* {t('TOPBAR.CREATE')}
                <span className="lines"></span> */}
              </Button>
              {user.isLogin && (account || user.tronWalletAddress) ? (
                <Button
                  className={wallet}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={() => copyTextById("headerWalletAddress")}
                >
                  {account
                    ? sortAddress(account)
                    : sortAddress(user.tronWalletAddress)}{" "}
                </Button>
              ) : (
                <Button
                  className={wallet}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  to="/wallet"
                  component={Link}
                >
                   {t('TOPBAR.CONNECT_WALLET')}
                </Button>
              )}
              <FormControl sx={{ m: 1, maxWidth: 120 }} style={{width: '120px', marginTop: '30px'}}>
            {/* <InputLabel id="demo-controlled-open-select-label" style={{color: '#fff'}}>{t('TOPBAR.LANGUAGE')}</InputLabel> */}
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              // open={open}
              // onClose={handleClose}
              // onOpen={handleOpen}
              defaultValue={language}
              // value={languages.name}
              label="Language"
              onClick={(e) => {
                i18next.changeLanguage(e.target.value)
              }}
            >
               {/* {languages.map(({ code, name, country_code }) => ( 
               
                <MenuItem key={country_code} value={code}>{{name}}</MenuItem>
               ))} */}
              <MenuItem value={'ja'} style={{color:'#fff'}}>日本語</MenuItem>
              <MenuItem value={'en'} style={{color:'#fff'}}>English</MenuItem>
            </Select>
          </FormControl>
            </div>
            
          </Drawer>
                
          <div>{productLogo}</div>
          <Grid container>
            <Grid item xs={10}>
            </Grid>
            <Grid item xs={2}>
              <IconButton
                className={drawericon}
                {...{
                  edge: "start",
                  color: "inherit",
                  "aria-label": "menu",
                  "aria-haspopup": "true",
                  onClick: handleDrawerOpen,
                }}
              >
                <MenuIcon
                  width="60px"
                  height="60px"
                  style={{ color: "#a9acaf", fontSize: "30px" }}
                />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href, uuid }, index) => {
      return (
        <Fragment key={uuid + index}>
          <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: Link,
              className: menuButton1,
            }}
          >
            <MenuItem className={menuMobile}>{label}</MenuItem>
          </Button>
        </Fragment>
      );
    });
  };

  const productLogo = (
    <Box style={{width: "130px"}}>
      <Link to="/">
        <Logo className="logoImg" />
        <h2 className="logo_text" style={{color:"#fff", fontSize:"14px", textAlign:"center"}}>{t('TOPBAR.NFT_TEXT_LOGO')}</h2> 
      </Link>
    </Box>
  );

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <div className="navbar-item">
            <div>
              <Button
                {...{
                  key: label,
                  color: "inherit",
                  to: href,
                  component: Link,
                  className: "dropdown-custom dropdown-toggle btn",
                }}
              >
                {label}
                {/* <Button
                  {...{
                    key: label,
                    color: "inherit",
                    to: href,
                    component: Link,
                    className: "dropdown-custom dropdown-toggle btn",
                  }}
                >
                  {label}
                </Button> */}
                <span className="lines"></span>
              </Button>
            </div>
          </div>

        </>
      );
    });
  };

  return (
    <>
      {/* <AppBar
        position={"fixed"}
        // className={history.location.pathname !== "/" ? "InnerHeader" : "Header"}
        elevation={0}
        style={{ backgroundColor: "transparent", border: "none" }}
      >
        <Container
          maxWidth={history.location.pathname !== "/" ? "lg" : false}
          className={containerHeight}
        >
          <ToastContainer />
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar> */}
      <ToastContainer />
      {mobileView ? displayMobile() : displayDesktop()}
      <a href="#" onClick={ (event) => scrollToTop(event) } id="back-to-top" className={`${ !headerPosition ? 'hideButton' : 'showButton' }`}></a>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose1}
      >
        <MenuItem>
          <Link to="/profile">My Profile</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/user">My Nft</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/resell-nft">Resell Nft</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/create-nft">Create NFT</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/notification">Notification</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/search">Search</Link>
        </MenuItem>
      </Menu>

      {/* {account && (
        <Dialog
          open={chainId !== ACTIVE_NETWORK}
          className="walletdailog"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="alert-dialog-title" className="dailogTitle">
            {"Network Error"}
          </DialogTitle>
          <DialogContent className="loginModal">
            <DialogContentText id="alert-dialog-description">
              <Box className="walletlist">
                <Typography>Please swich to {NetworkContextName}</Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )} */}
    </>
  );
}
