import React, {
  Suspense,
  Fragment,
  useContext,
  useEffect,
  useState,
} from "react";
import { Router, Switch, Route, useLocation } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { routes } from "src/routes";
import { createBrowserHistory } from "history";
import { UserContext } from "src/context/User";
import PageLoading from "src/component/PageLoading";
import AuthGuard from "src/component/AuthGuard";
import { ThemeProvider } from "@material-ui/core";
import { createMainTheme } from "src/theme";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { renderToStaticMarkup } from 'react-dom/server';
const history = createBrowserHistory();

function App() {
  const [theme, setTheme] = useState(createMainTheme(1));
  const user = useContext(UserContext);
 
  useEffect(() => {
    if (user.currentTheme === 1) {
      // document.documentElement.style.setProperty(
      //   "--background-color",
      //   "#0f0f11"
      // );
      // document.documentElement.style.setProperty(
      //   "--second-background-color",
      //   "#202020"
      // );
      // document.documentElement.style.setProperty("--main-color", "#ffffff");
      // document.documentElement.style.setProperty("--second-color", "#DFE6E9");
      setTheme(createMainTheme(1));
    } else if (user.currentTheme === 2) {
      document.documentElement.style.setProperty(
        "--background-color",
        "#ffffff"
      );
      document.documentElement.style.setProperty(
        "--second-background-color",
        "#e8e8e8"
      );
      document.documentElement.style.setProperty("--main-color", "#1C2252");
      document.documentElement.style.setProperty("--second-color", "#383838");
      setTheme(createMainTheme(2));
    }
  }, [user.currentTheme]);

  return (
    <div className="App">
      <ToastContainer />
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router history={history}>
            <RenderRoutes data={routes} />
          </Router>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;

function RenderRoutes(props) {
  const user = useContext(UserContext);
  return (
    <Suspense fallback={<PageLoading />}>
      {user.isLoading ? (
        <PageLoading />
      ) : (
        <Switch>
          {props.data.map((route, i) => {
            const Component = route.component;
            const Guard = route.guard ? AuthGuard : Fragment;
            const Layout = route.layout || Fragment;
            return (
              <Route
                exact={route.exact}
                key={i}
                path={route.path}
                render={(props) => (
                  <Guard>
                    <Layout>
                      {route.routes ? (
                        <RenderRoutes data={route.routes} />
                      ) : (
                        <Component {...props} />
                      )}
                    </Layout>
                  </Guard>
                )}
              />
            );
          })}
        </Switch>
      )}
    </Suspense>
  );
}
