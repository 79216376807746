import React, { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles, Box, TextField } from "@material-ui/core";
import apiConfig from "src/config/ApiConfig";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next'
const useStyles = makeStyles((theme) => ({
  board: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    boxShadow: "0 3px 6px rgba(255,255,255,0.24)",
    marginTop: "2px",
    borderRadius: "8px",
  },
  prompt: {
    color: '#727272',
    padding: "5px 5px 5px",
  },
  cCategory: {
    fontSize: "22px",
    borderBottom: `solid 1px rgba(0, 0, 0, 0.1)`,
    paddingBottom: "8px",
    paddingTop: "8px",
    color: "#727272",
    paddingLeft: "8px"
  },
  cInstruction: {
    color: "#727272",
    paddingTop: "5px",
    borderTop: `solid 1px rgba(0, 0, 0, 0.1)`,
  },
  cNode: {
    display: "grid",
    gridTemplateColumns: "1fr 5fr",
    alignItems: "center",
    cursor: "pointer",
    margin: "2px",
    padding: "2px",
    color: theme.mainColor,

    "& img": {
      width: "40px",
      height: "40px",
      objectFit: "cover",
      borderRadius: "50%"
    },
  },
}));

const SearchList = (props) => {
  const { board, prompt, cCategory, cInstruction, cNode } = useStyles();
  const [collections, setCollections] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const currentLanguageCode = localStorage.getItem("i18nextLng")  || 'en'
  const { t } = useTranslation();
  const getCollectionListHandler = async (search) => {
    try {
      const res = await axios.get(apiConfig.collectionList, {
        params: {
          search,
          limit: 3,
        },
      });
      if (res.data.statusCode === 200) {
        if (res.data.result.docs) {
          setCollections(res.data.result.docs);
        }
      }
    } catch (error) {
      console.log("ERRROR", error.message);
    }
  };

  const getProfileListHandler = async (search) => {
    try {
      const res = await axios.get(apiConfig.userList, {
        params: {
          search,
          limit: 3,
        },
      });
      if (res.data.statusCode === 200) {
        if (res.data.result.docs) {
          setProfiles(res.data.result.docs);
        }
      }
    } catch (error) {
      console.log("ERRROR", error.message);
    }
  };

  useEffect(() => {
    const { criteria } = props;
    if (criteria) {
      getCollectionListHandler(criteria);
      getProfileListHandler(criteria);
    }
  }, [props.criteria]);

  useEffect(() => {
    document.body.dir ="en"
  }, [currentLanguageCode, t]);
  return (
    <div className={board}>
      {!props.criteria && <div className={prompt}>{t('SEARCH_TOP.START_TYPING')}</div>}
      {props.criteria && (
        <div className={prompt}>
          <div>
            <div className={cCategory}>{t('SEARCH_TOP.COLLECTIONS')}</div>
            {collections.map((node) => (
              <Link to={`/collection/${node.displayName}`} key={node._id}>
                <div className={cNode}>
                  <img src={node.collectionImage} alt="" />
                  <div style={{ fontSize: "16px" }}>{node.displayName}</div>
                </div>
              </Link>
            ))}
          </div>
          <div style={{ textAlign: "center" }}>.&nbsp;.&nbsp;.</div>
          <div>
            <div className={cCategory}>{t('SEARCH_TOP.PROFILE')}</div>
            {profiles.map((node) => (
              <Link to={`/user?${node._id}`} key={node._id}>
                <div className={cNode}>
                  <img src="/images/profile.png" alt="" />
                  <div>
                    <div>{node.name}</div>
                    <div>{`${node.walletAddress.substring(0, 7)}...`}</div>
                  </div>
                </div>
              </Link>
            ))}
            <div style={{ textAlign: "center" }}>.&nbsp;.&nbsp;.</div>
          </div>
          <div className={cInstruction}>{t('SEARCH_TOP.SEARCH_ALLITEM')}</div>
        </div>
      )}
    </div>
  );
};

export default SearchList;
