import React from "react";
import App from "./App";
import UserAuthContext from "src/context/User";
import { useTranslation, withTranslation, Trans } from 'react-i18next';
const ThemedApp = () => {
  return (
    <UserAuthContext>
      <App />
    </UserAuthContext>
  );
};

export default ThemedApp;
