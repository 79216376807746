import React from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Footer from "./Footer";
import TopBar from "./TopBar";

const MainLayout = ({ children }) => {
  const history = useHistory();
  return (
    <div>
      <TopBar />
      <div
        style={
          history.location.pathname !== "/"
            ? { display: "block" }
            : { display: "none" }
        }
      ></div>

      <div>{children}</div>
      <Footer />
    </div>
  );
};

export default MainLayout;
