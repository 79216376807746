import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  List,
  ListItem,
  makeStyles,
  TextField,
  Button,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import TwitterIcon from "@material-ui/icons/Twitter";
import TelegramIcon from "@material-ui/icons/Telegram";
import { FaDiscord, FaFacebookF } from "react-icons/fa";
import { ImLinkedin2 } from "react-icons/im";

import { toast } from "react-toastify";
import axios from "axios";
import apiConfig, { websiteName } from "src/config/ApiConfig";
import { } from "react-feather";
import Logo from "./../../component/Logo";
import "../../scss/layout/_footer.scss";
import { useTranslation } from 'react-i18next'
const useStyles = makeStyles((theme) => ({
  footerSection: {
    position: "relative",
    paddingTop: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(4),
      // paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(4),
      // paddingBottom: theme.spacing(2),
    },
    "& h5": {
      fontWeight: "bold",
      fontSize: "16px",
      letterSpacing: "2px",
      textTransform: "uppercase",
    },
    "& ul": {
      paddingLeft: "0",
    },
    "& p": {
      marginBottom: "20px",
      marginTop: "10px",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "18px",
    },
  },
  input_fild: {
    backgroundColor: "#e1e1e1",
    borderRadius: "50px",
    color: "#000",
    height: "55px",
    width: "100%",
    borderBottom: "none",
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
      },
    },
    "& .MuiInputBase-input": {
      color: "#000",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
      borderWidth: 0,
    },
  },

  footerBg: {
    position: "absolute",
    bottom: "0",
    width: "100%",
    left: "0",
  },
  ListItem: {
    paddingLeft: "0px",
  },
  borderBottmo: {
    overflow: "hidden",
    background: "#000",
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
  signupBtn: {
    color: "#fff",
    // borderRadius: "10px",
    display: "flex",
    fontSize: "16px",
    fontWeight: "bold",
    height: "45px",
    minWidth: "50px",
    borderRadius: "50px",
    position: "absolute",
    top: "5px",
    right: "5px",
    boxShadow:
      "0px 8px 24px rgba(38, 50, 56, 0.1), 0px 16px 32px rgba(38, 50, 56, 0.08)",
    lineHeight: "36px",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: " 1px",
    background: "linear-gradient(180deg, #E25AF8 0%, #583DFF 100%)",
  },
  largeIcon: {
    width: 18,
    height: 18,
    marginRight: "8px",
  },
  icons: {
    justify: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justify: "center",
    },
  },
  inputBox: {
    position: "relative",
    background: "transparent",
    borderRadius: "28px",
    maxWidth: "400px",
    border: "1px solid #727272",
    "& input": {
      borderRadius: "25px",
      color: theme.mainColor,
    },
  },
  socialIcon: {
    display: " flex",
    alignItems: "center",
    marginTop: "10px",
    "& svg": {
      color: "#aeadad",
      cursor: "pointer",
      marginRight: " 10px",

      "&:hover": {
        color: "#39076a",
      },
    },
  },
  bottomIcon: {
    width: "38px",
    height: "38px",
    borderRadius: "25px",
    border: "1px solid #727272",
    paddingTop: "12px",
    paddingLeft: "12px",
    background:
      "linear-gradient(90deg, rgba(255, 255, 255, 0.1) -1.52%, rgba(255, 255, 255, 0.024) 104.35%)",
    marginRight: "5px",
  },
  header: {
    color: theme.mainColor
  },
  content: {
    color: theme.secondColor
  }
}));

export default function Liquidity() {
  const classes = useStyles();
  const currentLanguageCode = localStorage.getItem("i18nextLng")  || 'en'
  const { t } = useTranslation();
  const [email, setEmail] = React.useState("");
  const UserSubscribe = async () => {
    try {
      const res = await axios.post(apiConfig.userSubscribe, {
        email: email,
      });

      if (res.data.statusCode === 200) {
        if (res.data.result.docs) {
          // setNoOfPages(res.data.result.pages);
          // setActivityNFTList(res.data.result.docs);
          console.log(res.data.result);
        }
      }
      // setIsLoading(false);
    } catch (error) {
      console.log("ERROR", error);
      // setIsLoading(false);
    }
  };
  useEffect(() => {
    document.body.dir ="en"
  }, [currentLanguageCode, t]);
  return (
    <>
      <footer className="footer-light">
        <div className="wrap">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-6 col-xs-1">
                <div className="widget">
                  <h5>{t('FOOTER.CONTENT')}</h5>
                  <ul>
                    <li><Link to="/marketplace">{t('FOOTER.EXPLORE')}</Link></li>
                    {/* <li><Link to="/help-center">{t('FOOTER.HELP_CENTER')}</Link></li> */}
                    {/* <li><Link to="/">{t('FOOTER.BLOG')}</Link></li>
                    <li><Link to="/">{t('FOOTER.SUGGET_FEATURE')}</Link></li> */}
                  </ul>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-1"></div>
              <div className="col-md-4 col-sm-6 col-xs-1">
                <div className="widget">
                  <h5>{t('FOOTER.NEW_LETTER')}</h5>
                  <p>{t('FOOTER.SIGNUP_FOROUT')}</p>
                  <form action="#" className="row form-dark" id="form_subscribe" method="post" name="form_subscribe">
                    <div className="col text-center">
                      <input className="form-control" id="txt_subscribe" name="txt_subscribe"
                        placeholder={t('FOOTER.ENTER_EMAIL')} type="text"
                        onChange={(e) => setEmail(e.target.value)}
                      ></input>
                      <a aria-current="page"
                        id="btn-subscribe"
                      // href="/home2Grey"
                      >
                        <i className="arrow_right bg-color-secondary"
                          onClick={() => {
                            UserSubscribe();
                            setEmail("");
                          }}></i>
                      </a>
                      <div className="clearfix"></div>
                    </div>
                  </form>
                  <div className="spacer-10"></div><small>{t('FOOTER.YOUREMAIL')}</small>
                </div>
              </div>
            </div>
          </div>
          <div className="subfooter">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="de-flex">
                    <div className="de-flex-col">
                      <span>
                        <img alt="" className="f-logo d-1" src="/images/logo/logo-v4.png"></img>
                        <span className="copy">© {t('FOOTER.ALL_RIGHT')}</span>
                      </span>
                    </div>
                    <div className="de-flex-col">
                      <div className="social-icons">
                        <span><i className="fab fa-twitter"></i></span>
                        <span><i className="fab fa-telegram-plane"></i></span>
                        <span><i className="fab fa-facebook-square"></i></span>
                        <span><i className="fab fa-linkedin-in"></i></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <Box className={classes.footerSection}>
        <Box
          style={{
            background:
              "radial-gradient(circle at 20% 40%,#ffffff23 0%,#ffffff20 300px)",
            boxShadow: "0 0 1px 2px #ffffff20",
          }}
        >
          <Container maxWidth="lg">
            <Grid container justifyContent="space-around" spacing={1}>
              <Grid
                item
                xs={12}
                md={6}
                style={{
                  margin: "auto",
                }}
              >
                <Typography variant="h1" className={classes.header}>
                  Get the latest updates
                </Typography>

                <Box mt={2}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12}>
                      <Box className={classes.inputBox}>
                        <TextField
                          placeholder="Your e-mail"
                          variant="filled"
                          color="secondary"
                          onChange={(e) => setEmail(e.target.value)}
                          InputProps={{ disableUnderline: true }}
                          fullWidth
                        />
                        <Button
                          onClick={() => {
                            UserSubscribe();
                            setEmail("");
                          }}
                          className={classes.signupBtn}
                        >
                          <img src="images/home-icon/arrow.svg" />
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  src="/images/home-icon/foot-banner.png"
                  style={{ margin: "20px 0", maxWidth: "100%" }}
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box style={{ margin: "40px 10px 0" }}>
          <Container maxWidth="lg">
            <Grid container justifyContent="space-around" spacing={1}>
              <Grid item xs={12} md={6}>
                <img
                  src="/images/logo/logo.svg"
                  style={{ maxWidth: "160px" }}
                />
                <Box className={classes.socialIcon}>
                  <div className={classes.bottomIcon}>
                    <a href="/" target="_blank">
                      <TwitterIcon />
                    </a>
                  </div>
                  <div className={classes.bottomIcon}>
                    <a href="/" target="_blank">
                      <TelegramIcon />
                    </a>
                  </div>
                  <div className={classes.bottomIcon}>
                    <a href="/" target="_blank">
                      <FaDiscord style={{ width: "24px", height: "24px" }} />{" "}
                    </a>
                  </div>
                  <div className={classes.bottomIcon}>
                    <a href="/" target="_blank">
                      <ImLinkedin2
                        style={{
                          width: "24px",
                          height: "24px",
                          paddingBottom: ".3rem",
                        }}
                      />
                    </a>
                  </div>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="h2" className={classes.content}>Content</Typography>
                <List className="listingFooter">
                  <ListItem>
                    <Link to="/marketplace">Explore</Link>
                  </ListItem>
                  <ListItem>
                    <Link to="/help-center">Help center</Link>
                  </ListItem>
                  <ListItem>
                    <Link to="/">Blog</Link>
                  </ListItem>
                  <ListItem>
                    <Link to="/">Suggest feature</Link>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box
          style={{
            background:
              "linear-gradient(90.1deg, #E25AF8 -7.18%, #583DFF 103.76%)",
            display: "flex",
            justifyContent: "center",
            padding: "15px",
          }}
        >
          <Link to="/" style={{ color: "#0f0f11", fontSize: "18px" }}>
            © All rights reserved
          </Link>
        </Box>
      </Box> */}
    </>
  );
}
