import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { SUPPORTED_CHAIN_IDS } from "src/constants";

// import { BinanceConnector } from "@bscswap/binance-connector";
const NETWORK_URL = "https://bsc-dataseed1.defibit.io"; // process.env.REACT_APP_NETWORK_URL

export const injected = new InjectedConnector({
  supportedChainIds: SUPPORTED_CHAIN_IDS,
});

// export const mathWalletInjected = new InjectedConnector({
//   supportedChainIds: [1, 42, 56, 97],
// });

export const walletconnect = new WalletConnectConnector({
  rpc: {
    4: NETWORK_URL,
  },
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
  pollingInterval: 15000,
});

// export const walletconnectMainnet = new WalletConnectConnector({
//   rpc: {
//     1: "wss://mainnet.infura.io/ws/v3/2731597d41324dfbba606b6bca52f636'",
//   },
//   bridge: "https://bridge.walletconnect.org",
//   qrcode: true,
//   pollingInterval: 15000,
// });

// export const binanceinjected = new BinanceConnector({
//   supportedChainIds: [1, 42, 56, 97],
// });

export const SUPPORTED_WALLETS = [
  {
    name: "METAMASK",
    data: {
      connector: injected,
      name: "MetaMask",
      iconName: "images/logo/MetaMask.png",
      description: "Easy-to-use browser extension.",
      href: null,
      color: "#E8831D",
    },
  },

  {
    name: "WALLETCONNECT",
    data: {
      connector: walletconnect,
      name: "Wallet Connect",
      iconName: "images/walletConnect.png",
      description: "Your Gateway to the World of Blockchain",
      href: null,
      color: "#000000",
    },
  }
];
