import React from "react";
import { makeStyles, Box, LinearProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  // root: {
  //   alignItems: "center",
  //   //backgroundColor: theme.palette.background.default,
  //   display: "flex",
  //   flexDirection: "column",
  //   height: "100%",
  //   justifyContent: "center",
  //   left: 0,
  //   padding: theme.spacing(3),
  //   position: "fixed",
  //   top: 0,
  //   width: "100%",
  //   zIndex: 2000,
  //   backgroundColor: theme.backgroundColor,
  // },
  root: {
      position: "fixed",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      backgroundColor: theme.backgroundColor,
      zIndex: 2000,
      
  },
  loader: {
    position: "fixed",
    top:" 40%",
    left:"42%",
    width: "200px",
    height: "200px",
    margintop: "-32.5px",
    marginleft: "-32.5px",
    display: "flex",
    justifyContent:'center',
    textalign: 'center',
    color:" #fff",
    zindex: 2,
  },
  loading : {
    color: "#fff",
    position: "fixed",
    top:" 55%",
    left:" 43%",
    fontSize:"26px"
  },
  progressBar: {
    height: "3px",
  },
  dataLoader: {
    height: 450,
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

export default function PageLoading() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Box width={300}>
        {/* <LinearProgress height={10} /> */}
        <img className={classes.loader} src="/images/Spinner-1s-800px.svg" alt="Now Loading…"/>
        <p className={classes.loading}>Now Loading…</p>
      </Box>
    </div>
  );
}

export function DataLoading() {
  const classes = useStyles();
  return (
    <div className={classes.dataLoader}>
      {/* <LinearProgress height={10} /> */}{" "}
      <Box width={300}>
        <img className={classes.loader} src="/images/Spinner-1s-800px.svg" alt="Now Loading…"/>
        <p className={classes.loading}>Now Loading…</p>
      </Box>
    </div>
  );
}
