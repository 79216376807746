import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import { makeStyles, Box, TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchList from "./SearchList";
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

const useStyles = makeStyles((theme) => ({
  wrapBox: {
    position: "relative",
  },
  inputBox: {
    position: "relative",
    marginLeft: "30px",
    background: theme.backgroundColor,
    borderRadius: "8px",
    border: `solid 1px ${theme.secondBackgroundColor}`,
    "& input": {
      color: theme.secondColor,
      borderRadius: "20px",
    },
    zIndex: "101",
  },
  input_fild: {
    // width: "400px",
    // height: "44px",

    "& .MuiFilledInput-input": {
      padding: "4px 11px",
      width: "274px",
      height: "34px",
      boxSizing: "border-box",
      backgroundColor: "rgba(255,255,255,0.1)",
      color: "#ffffff"
    },

    "& .MuiFilledInput-adornedStart": {
      paddingLeft: "0",
    },

    "& .MuiInputAdornment-positionStart": {
      marginRight: "0",
    },

    "& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)":
    {
      marginTop: "0",
    },

    "& .MuiIconButton-root": {
      padding: "10px",
    },
    "& .MuiFilledInput-root": {
      borderRadius: "0",
      backgroundColor: "unset",
    },
  },
  searchList: {
    position: "absolute",
    left: "0",
    width: "300px",
    zIndex: "101",
  },
  searchIcon: {
    marginLeft: "10px",
    marginRight: "10px",
  },
  blurBackground: {
    zIndex: "100",
    position: "fixed",
    background: `${theme.mainColor}33`,
    backdropFilter: "blur(0px)",
    left: "0",
    top: "0",
    width: "100%",
    height: "100%",
    transition: "all .1s",
  },
}));
// const languages = [
//   {
//     dir:"ja",
//     code: 'ja',
//     name: 'Japan',
//   },
//   {
//     dir:"en",
//     code: 'en',
//     name: 'English',
//   }
// ]

const SearchBar = () => {
  const currentLanguageCode = localStorage.getItem("i18nextLng")  || 'en'
  const { t } = useTranslation()
  const {
    inputBox,
    input_fild,
    wrapBox,
    searchList,
    searchIcon,
    blurBackground,
  } = useStyles();
  const [isShowCriteriaPanel, setIsShowCriteriaPanel] = useState(false);
  const [criteria, setCriteria] = useState("");
  const refActiveState = useRef(false);
  const refSearchList = useRef(null);

  const onClickMouse = useCallback(
    (e) => {
      setTimeout(() => {
        if (
          refActiveState?.current === true ||
          refSearchList?.current?.contains(e.target)
        ) {
          setIsShowCriteriaPanel(true);
        } else {
          setIsShowCriteriaPanel(false);
        }
      }, 0);
    },
    [setIsShowCriteriaPanel, refActiveState, refSearchList]
  );

  useEffect(() => {
    document.addEventListener("mousedown", onClickMouse);
    document.body.dir ="en"
    return () => {
      document.removeEventListener("mousedown", onClickMouse);
    };
  }, [currentLanguageCode, t]);

  return (
    <div className={wrapBox}>
      {/* <div
        className={blurBackground}
        style={{
          visibility: `${isShowCriteriaPanel ? "visible" : "hidden"}`,
          backdropFilter: `${isShowCriteriaPanel ? "blur(3px)" : "blur(0px)"}`,
        }}
      ></div> */}
      {/* <Box className={inputBox}>
        <TextField
          placeholder="search item here..."
          variant="filled"
          color="secondary"
          onFocus={() => (refActiveState.current = true)}
          onBlur={() => (refActiveState.current = false)}
          InputProps={{
            disableUnderline: true,
            // startAdornment: (
            //   <InputAdornment position="start">
            //     <img src="images/icon/search.svg" className={searchIcon} />
            //   </InputAdornment>
            // ),
          }}
          fullWidth
          className={input_fild}
          value={criteria}
          onChange={(e) => setCriteria(e.target.value)}
        />
      </Box> */}

      <input
        id="quick_search"
        className="xs-hide"
        name="quick_search"
        placeholder={t('SEARCH_TOP.SEARCH_ITEM')}
        type="text"
        onFocus={() => (refActiveState.current = true)}
        onBlur={() => (refActiveState.current = false)}
        value={criteria}
        onChange={(e) => setCriteria(e.target.value)}
      ></input>

      {isShowCriteriaPanel && (
        <div className={searchList} ref={refSearchList}>
          <SearchList criteria={criteria} />
        </div>
      )}
    </div>
  );
};

export default SearchBar;
